import LottieFolder from '../Articles/LottieAnimation/LottieFolder';
import IncyteLogo from '../../Assets/Images/HomeLogo.svg';
import React from 'react';
import SearchBar from '../../Reusable/SearchBar';
import ScrollToTop from '../../ScrollToTop';
import { useNavigate } from 'react-router-dom';


function Redirect() {
    const navigate = useNavigate();
    const rndInt = Math.floor(Math.random() * 10);

    return (
        <div className='flex flex-col items-center p-5 xsm:px-2 sm:px-2' >
            <div className="flex flex-col w-full">

                <div className="mb-6">
                    <SearchBar homeLogo={false}/>
                </div>

                <button onClick={() => navigate("/")} className='py-2 xsm:px-4 xsm:py-1 sm:px-4 sm:py-1 px-6 bg-[#005CAB] hover:bg-[#0075d9] w-fit rounded-lg flex gap-x-1 mr-auto ml-14 xsm:ml-4 sm:ml-4  mb-10' >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M15 18l-6-6 6-6" /></svg>
                    <p className='text-white font-normal xsm:text-[12px] sm:text-[12px] my-auto'>Back</p>
                </button>
            </div>

            <ScrollToTop />

            <div className="flex flex-col w-full justify-center items-center mt-10">

                <div className="flex">
                    <p className="text-[#2E5DAB] text-4xl md:text-3xl xsm:text-2xl sm:text-2xl font-semibold tracking-wide font-sans uppercase" >THE ARTICLE IS NOT AVAILABLE FOR ANALYSIS . . . <br />YOU'RE BEING REDIRECTED TO PMC</p>
                </div>

                <div className="flex flex-col justify-center items-center relative ">

                    <div className="flex justify-center items-center w-full h-full xsm:h-fit sm:w-[85%] md:w-[80%] md:h-fit sm:h-fit">
                        <LottieFolder />
                    </div>

                    <div className="flex justify-center items-center w-full h-full my-auto xsm:mt-32 sm:mt-32 md:mt-32 mt-44 lg:mt-48 absolute animation z-10">
                        <img src={IncyteLogo} alt="" className="h-[200px] w-[200px] sm:h-[150px] sm:w-[150px] xsm:h-[150px] xsm:w-[150px] md:h-[160px] md:w-[160px]"></img>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Redirect;