import React, { useContext } from 'react'
import { MyContext } from "../../App"
import HomeArticleCard from './HomeArticleCard';
import LottieFolder from '../Articles/LottieAnimation/LottieFolder';
import IncyteLogo from "../../Assets/Images/HomeLogo.svg";


function Articles() {
    const { articles, Loading } = useContext(MyContext)

    return (
        <>
            <div className='font-sans flex flex-col gap-y-6 my-4 w-[85%] sm:w-[90%] xsm:w-[90%] mx-auto' >
                {Loading &&

                    <div className="flex flex-col w-full justify-center items-center mt-10">

                        <div className="flex">

                            <p className="text-[#005CAB] text-xl font-semibold tracking-wide font-sans uppercase" >We're searching for the last articles!</p>

                        </div>

                        <div className="flex flex-col justify-center items-center h-[250px] w-[250px]">

                            <div className="flex justify-center items-center w-full h-full">

                                <LottieFolder />

                            </div>

                            <div className="flex justify-center items-center w-full h-full -mt-[145px] animation z-10">

                                <img src={IncyteLogo} alt="" className="h-[100px] w-[100px]"></img>

                            </div>

                        </div>

                    </div>

                }
                {!Loading && articles !== [] && articles?.map((article, index) => {
                    return (
                        <HomeArticleCard article={article} />
                    )
                })}
            </div>
        </>
    )
}

export default Articles