import React from 'react'
import Incyte from "../../Assets/Images/IncyteWhite.svg"
import Gmail from "../../Assets/Icons/gmail.svg"
import { BsLockFill } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import { useNavigate } from "react-router-dom"

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

function Login() {
    const navigate = useNavigate()
    const { instance } = useMsal();

    function handleLogin() {
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    return (
        <div className='h-fit min-h-full py-12  w-full bg-gradient-to-b from-[#005CAB] to-[#88D4F2] flex flex-col items-center justify-center gap-y-2' >
            <div className='h-[200px] '>
                <img src={Incyte} alt="" className='w-full h-full' />
            </div>
            <p className='text-white text-6xl lg:text-5xl xmd:text-4xl sm:text-3xl xsm:text-3xl md:text-3xl font-sans '>Login</p>
            <button className='flex flex-row rounded-md bg-white font-sans text-[#5A5A61] w-[35%] sm:w-[95%] xsm:w-[95%] md:w-[95%] h-9 border-2 border-white items-center justify-center my-6' >
                <img src={Gmail} alt="" />
                Continue with Google
            </button>
            <div className='flex flex-col gap-y-4 xmd:gap-y-2 w-full items-center mx-auto'>


                <div className='flex flex-row w-[35%] sm:w-[95%] xsm:w-[95%] md:w-[95%] items-center ' >
                    <input className='h-9 rounded-md bg-transparent border-2 border-white w-full placeholder:text-white focus:outline-none px-4 text-white' type="email" placeholder='Email' />
                    <span className='-ml-10'><MdEmail color='#4c8cb0' size={20} /></span>
                </div>
                <div className='flex flex-row w-[35%] sm:w-[95%] xsm:w-[95%] md:w-[95%] items-center ' >
                    <input className='h-9 rounded-md bg-transparent border-2 border-white w-full placeholder:text-white focus:outline-none px-4 text-white' type="password" placeholder='Password' />
                    <span className='-ml-10'><BsLockFill color='#4c8cb0' size={20} /></span>
                </div>
                <div className='flex flex-row w-[35%] sm:w-[95%] xsm:w-[95%] md:w-[95%] items-center gap-x-1  ' >
                    <input className='h-4 w-4' type="checkbox" placeholder='Password' />
                    <span className='font-sans text-white text-[14px] '>Remember me.</span>
                </div>
            </div>


            <div className='flex flex-col w-full items-center mt-2 gap-y-2' >
                <button onClick={() => handleLogin()} className='bg-white rounded-lg border-2 border-white font-sans  text-[#005CAB] py-2 w-[15%] sm:w-[75%] xsm:w-[75%] md:w-[75%] font-semibold shadow-md hover:shadow-lg'>Login</button>
                <p className='text-[#E0E0E0] font-sans'>If you are not registered</p>

                <button onClick={() => navigate("/register")} className='bg-transparent border-2 border-white rounded-lg font-sans text-white py-2 w-[15%]  sm:w-[75%] xsm:w-[75%] md:w-[75%] font-semibold shadow-md hover:shadow-lg'>Register</button>
            </div>
        </div>

    )
}

export default Login