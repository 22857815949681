import React, { useContext } from 'react'
import { baseUrl, MyContext } from '../../App';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { HiDownload } from "react-icons/hi"
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios"
import Metadata from '../../Reusable/Metadata';


function ArticleCard({ article }) {
    const { getSingleArticle, Loading } = useContext(MyContext)

    const [loading, SetLoading] = React.useState(false)

    async function handleDownloadPDF() {
        SetLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/download_pdf?id=${article.id}`)
            window.open(res.data.url ?? res.data.path, '_blank');
        } catch (error) {
            console.log(error)
        }
        SetLoading(false)
    }

    if (!article)
        return (<></>)

    return (
        <div className='flex flex-row justify-center items-center sm:flex-col xsm:flex-col md:items-start gap-x-2 border-b py-4 w-[95%] '>
            <div className='w-[75%] text-left sm:w-full xsm:w-full' >
                <div className="flex flex-row gap-x-2 items-center" >
                    <p onClick={() => article.id && getSingleArticle(article.id)} className='text-[#005CAB] text-xl font-sans mb-1 hover:underline hover:cursor-pointer '>
                        {article.title}
                    </p>
                </div>
                <Metadata article={article}/>
            </div>

            <div className='w-[30%] md:mt-4 flex flex-row sm:w-full xsm:w-full'>
                <div className=' flex flex-col gap-y-2 items-center justify-center w-[75%] ' >
                    <button onClick={() => handleDownloadPDF()} className='text-[#005CAB] flex items-center justify-center hover:shadow-md active:shadow-inner font-semibold font-sans gap-x-1 rounded-lg shadow border py-1 px-3 w-[75%] xsm:text-[12px] md:text-[12px] sm:text-[12px]'>
                        {loading ? <CircularProgress size={25} color="inherit" /> :
                            <>
                                Download
                                <HiDownload color='#005CAB' />
                            </>
                        }
                    </button>
                </div>
                <div className='w-[25%] md:w-[45%] xmd:w-[40%] my-auto mx-auto'>
                    <CircularProgressbarWithChildren value={article.score * 100}>
                        <p className='text-[#005CAB] text-xs font-sans xsm:text-[10px] md:text-[10px] sm:text-[10px]'>Match level</p>
                        <p className='text-[#005CAB] text-xs font-sans xsm:text-[10px] md:text-[10px] sm:text-[10px]'>{(article.score * 100).toFixed(2)}%</p>
                    </CircularProgressbarWithChildren>
                </div>
            </div>
        </div>
    )
}

export default ArticleCard
