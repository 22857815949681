import React, { useState } from "react";
import Articles from "./Articles";
import Favorite from "./Favorite";
import headerImage from "../../Assets/Images/Header.png"
import LottieCoffee from "../Articles/LottieAnimation/LottieCoffee";
import SearchBar from '../../Reusable/SearchBar';
import { MyContext } from "../../App";


function Homepage() {
  const [index, setIndex] = useState(1)
  const { userData } = React.useContext(MyContext)
  // var userData = JSON.parse(localStorage.getItem('userData'))

  return (
    <>
      <div style={{ backgroundImage: `url(${headerImage})`, backgroundSize: "cover" }} className="w-full py-10" >
        <SearchBar homeLogo={true} />
      </div>

      <div className="flex flex-col">

        <div className="flex flex-col mt-12 gap-y-2">
          <div className="flex flex-row px-3 w-full justify-center items-center">

            <div className="flex">

              {index === 1 && <p className="text-[#005CAB] text-2xl sm:text-lg xsm:text-lg md:text-xl font-normal tracking-wide font-sans"> Hi <span className="font-semibold">{userData?.givenName} {userData?.surname}</span>, would you like to take a peak at most popular searches?</p>}

              {index === 3 && <p className="text-[#005CAB] text-2xl sm:text-lg xsm:text-lg md:text-xl font-normal tracking-wide font-sans"> Hi <span className="font-semibold">{userData?.givenName} {userData?.surname}</span>, would you like to take a peak at your favourite Articles?</p>}

            </div>

            <div className="flex w-[150px] h-[150px]">

              <LottieCoffee />

            </div>

          </div>
          <select onChange={(e) => setIndex(parseInt(e.target.value))} className="w-[90%] focus:outline-none mx-auto bg-[#00ACEE] rounded-lg font-semibold text-[16px] flex items-center justify-center py-1 pl-3 text-white xl:hidden lg:hidden xmd:hidden md:hidden">

            <option value={1} className="bg-white text-black" >
              Most read articles
            </option>

            <option value={3} className="bg-white text-black" >
              Favourites
            </option>

          </select>

          <div className="flex flex-row items-center w-[60%] xmd:w-[80%] md:w-[95%] xsm:w-[90%] sm:w-[95%] justify-center mx-auto gap-x-4 sm:hidden xsm:hidden " >
            <button onClick={() => setIndex(1)} className={`${index === 1 ? "bg-[#57ADEE] text-white" : "bg-[#F1F2F2]"} hover:shadow-lg hover:bg-[#57ADEE] font-semibold py-3 px-7 w-[30%] rounded-xl font-sans hover:text-white xsm:text-[10px] xsm:px-2 sm:text-[10px] sm:px-2 md:px-2 md:text-[12px]`}>Most read articles</button>

            <button onClick={() => setIndex(3)} className={`${index === 3 ? "bg-[#57ADEE] text-white" : "bg-[#F1F2F2]"} hover:shadow-lg hover:bg-[#57ADEE] font-semibold py-3 px-7 w-[30%] rounded-xl font-sans hover:text-white xsm:text-[10px] xsm:py-5 xsm:px-2 sm:text-[10px] sm:px-2 md:px-2 md:text-[12px]`}>Favourites</button>
          </div>

          <div className="flex flex-col mt-2 gap-y-4">

            {index === 1 && <Articles />}

            {index === 3 && <Favorite />}

          </div>
        </div>
      </div>

    </>

  );
}

export default Homepage;