import React from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../ScrollToTop";
import LottieCat from "./LottieAnimation/LottieCat";
import './404.css';


function Errornotfound() {

  const navigate = useNavigate ()

  return (

    <div className="errorpage">

      <ScrollToTop />

      <div className="flex flex-col w-full justify-center items-center mt-10">

        <div>

        <button onClick={() => navigate("/")} className='py-2 xsm:px-4 xsm:py-1 sm:px-4 sm:py-1 px-6 bg-[#005CAB] hover:bg-[#0075d9] w-fit rounded-lg flex gap-x-1 mr-auto ml-14 xsm:ml-4 sm:ml-4  mb-10' >
          
          <p className='text-white font-normal xsm:text-[12px] sm:text-[12px] my-auto'>Back Home</p>

        </button>

        </div>

        <div className="flex">

          <p className="text-[#005CAB] text-4xl font-semibold tracking-wide font-sans uppercase" >Oh no, something went wrong!<br/> But you found his magnificence, enjoy his presence</p>

        </div>

        <div className="flex w-[750px] h-[750px]">

          <LottieCat />

        </div>

      </div>

    </div>

  );
}

export default Errornotfound;
