import React, { createContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Routing from "./Navigation/Routes";
import './App.css';
import 'react-circular-progressbar/dist/styles.css';
import axios from "axios"

import { ReactSession } from 'react-client-session';
import { useBeforeunload } from 'react-beforeunload';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'react-spring-bottom-sheet/dist/style.css'
import { filterTypes } from './Reusable/SearchBar';

export const baseUrl = process.env.REACT_APP_NODE_ENV === "local" ? process.env.REACT_APP_BASE_URL + "/api" : "/api";


export const MyContext = createContext({})

export const sortingTypes = {
    "relevance": 1,
    "newest": 2,
    "oldest": 3
}

function App() {
    const navigate = useNavigate()
    const [Loading, SetLoading] = useState(false)
    const [articles, SetArticles] = useState([])
    const [singleArticle, SetSingleArticle] = useState([])
    const [query, setQuery] = useState(undefined)
    const [result, SetResult] = useState([])
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [FavoriteArticles, SetFavoriteArticles] = React.useState([]);
    const [filter, setFilter] = React.useState(filterTypes.full);
    const [sorting, setSorting] = React.useState(sortingTypes.relevance);
    const [userData, SetUserData] = React.useState(null)
    const handlePdfFlag = {
        articles,
        currentItems,
        pageCount,
        singleArticle,
        query,
        result,
        FavoriteArticles,
        filter,
        sorting
    }
    ReactSession.setStoreType("localStorage");

    React.useEffect(() => {
        const WebSiteContent = ReactSession.get("Settings");
        WebSiteContent?.articles && SetArticles(WebSiteContent?.articles)
        WebSiteContent?.singleArticle && SetSingleArticle(WebSiteContent?.single)
        WebSiteContent?.query && setQuery(WebSiteContent?.query)
        WebSiteContent?.currentItems && setCurrentItems(WebSiteContent?.currentItems)
        WebSiteContent?.pageCount && setPageCount(WebSiteContent?.pageCount)
        WebSiteContent?.result && SetResult(WebSiteContent?.result)
        WebSiteContent?.FavoriteArticles && SetFavoriteArticles(WebSiteContent?.FavoriteArticles)
        WebSiteContent?.filter && setFilter(WebSiteContent?.filter)
        WebSiteContent?.sorting && setSorting(WebSiteContent?.sorting)
    }, [])

    useBeforeunload((event) => {
        ReactSession.set("Settings", handlePdfFlag);
        localStorage.removeItem("userData")
    });

    async function getArticles() {
        SetLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/home`)
            SetArticles(res.data)
        } catch (error) {
            console.log(error)
        }
        SetLoading(false)
    }

    function refreshArticlesList() {
        setCurrentItems(result.slice(itemOffset, itemOffset + 5))
    }

    async function handleSearch(e) {
        if (!query)
            return

        e.preventDefault()
        navigate('/results')
        SetLoading(true)
        setCurrentItems([])
        setSorting(sortingTypes.relevance)

        try {
            const res = (await axios.get(`${baseUrl}/search?query=${query}&filter=${filter}`));
            SetResult(res.data)
            setCurrentItems(res.data.slice(itemOffset, itemOffset + 5))
            setPageCount(Math.ceil(res.data.length / 5))
        } catch (error) {
            console.log(error)
        }
        SetLoading(false)
    }

    function HandlePageChange() {
        setCurrentItems(result?.slice(itemOffset, itemOffset + 5));
        setPageCount(Math.ceil(result?.length / 5));
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * 4) % result?.length;
        setItemOffset(newOffset);
        window.scrollTo(0, 0);
    };

    const [open, setOpen] = React.useState(false);

    const [message, SetMessage] = React.useState()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    async function getSingleArticle(id) {
        SetLoading(true)
        try {
            navigate('/fullArticle')
            const article = (await axios.get(`${baseUrl}/article?id=${id}${query ? ("&query=" + query) : ""}`)).data
            SetSingleArticle(article)
            SetLoading(false)
        } catch (error) {
            if (error?.response?.status === 314) {
                navigate('/redirect')
                await new Promise(r => setTimeout(r, 5000));
                window.location.replace(error.response.headers.location)
                return
            }
            console.log(error)
        }
    }

    let MainData = {
        Loading, SetLoading,
        articles,
        handlePageClick, pageCount, currentItems,
        query, setQuery,
        singleArticle,
        FavoriteArticles,
        handleSearch,
        getSingleArticle,
        result, SetResult,
        filter, setFilter,
        sorting, setSorting,
        refreshArticlesList, userData
    }

    useEffect(() => {
        HandlePageChange()
    }, [itemOffset, result])

    React.useEffect(() => {
        CheckLogin()
        getArticles()
    }, [])
    const { pathname } = useLocation()

    React.useEffect(() => {
        CheckLogin()
    }, [pathname])

    const [open2, setOpen2] = React.useState(false);
    console.log(process.env.REACT_APP_BASE_URL, process.env.REACT_APP_NODE_ENV, baseUrl)
    async function CheckLogin() {
        try {
            const res = await axios.get(`/login/attrs`)
            // const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/login/attrs`)
            const userData =
            {
                displayName: res?.data?.displayname[0],
                givenName: res?.data?.givenname[0],
                surname: res?.data?.surname[0],
                email: res?.data?.emailaddress[0],
                name: res?.data?.name[0]
            }
            SetUserData(userData)
            localStorage.setItem("userData", JSON.stringify(userData))
        } catch (error) {
            console.log(error)
            if (error.response.status === 401 || error.response.status === 403) {
                window.location.replace(`/login`)
                localStorage.removeItem("userData")
                SetUserData(null)
            }
        }
    }

    async function HandleLogOut() {
        try {
            SetUserData(null)
            localStorage.removeItem("userData")
            window.location.replace('/logout')
        } catch (error) {
            console.error("Logout failed: " + error)
        }
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />

            <div className={`App  relative flex flex-row `}>
                <MyContext.Provider value={{ ...MainData }}>
                    {userData !== null &&
                        <button onClick={() => HandleLogOut()}
                            className='bg-red-700 text-white items-center justify-center px-6 py-2 absolute top-3 right-5 rounded-lg font-bold'>
                            Sign out
                        </button>
                    }
                    <div className='cssAnimation' style={{ width: open2 ? `calc(100% - 428px)` : "100%" }}>
                        <Routing />
                    </div>

                </MyContext.Provider>
            </div>
        </>
    );
}

export default App;
