import React, { useContext } from 'react'
import { BiSearchAlt2 } from "react-icons/bi"
import { MyContext } from "../App"
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Logo from '../Assets/Images/HomeLogo.svg';

export const filterTypes = {
    "full": 3,
    "external": 1,
    "internal": 2
}

function SearchBar({ homeLogo, Loading }) {
    const { query, setQuery, handleSearch, filter, setFilter } = useContext(MyContext)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const navigate = useNavigate()
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {homeLogo &&
                <div onClick={() => navigate('/')} className="mx-auto mb-12 w-[20%] xmd:w-[30%] xsm:w-[60%] sm:w-[60%] md:w-[40%] hover:cursor-pointer">
                    <img src={Logo} alt="mainlogo" className="w-full" />
                </div>
            }

            <div className='flex flex-row w-full px-8 items-center'>
                <div className="flex flex-row w-full xsm:flex-col sm:flex-col md:flex-col justify-center items-center gap-4">

                    {!homeLogo &&
                        <div onClick={() => navigate('/')} className="flex justify-center items-center w-[15%] xmd:w-[20%] md:w-[30%] xmd:w-[30%] xsm:w-[30%] hover:cursor-pointer">
                            <img src={Logo} alt="mainlogo" className="w-full h-full" />
                        </div>
                    }

                    <div className="flex flex-row gap-4 w-full xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse">
                        <div className="flex justify-center items-center w-[15%] xsm:w-full sm:w-full md:w-full xmd:w-[20%]">

                            <button
                            disabled={Loading}
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className="px-6 h-11 disabled:opacity-50 sm:h-8 xsm:h-8 md:h-10 bg-[#005CAB] hover:bg-[#0075d9] rounded-lg text-white font-normal xmd:px-4"
                            >
                                Search filter
                            </button>

                        </div>

                        <div className="flex flex-col w-full w-full mx-auto xsm:w-full sm:w-full md:w-[80%] xmd:w-[60%] lg:w-[70%]">
                            <form onSubmit={(e) => !Loading && handleSearch(e)} className="flex flex-row items-center w-full">
                                <input  disabled={Loading}  onChange={e => setQuery(e.target.value)} defaultValue={query} className="border-y border-l disabled:opacity-50 focus:outline-none h-11 md:p-3 md:h-10 sm:h-8 xsm:h-8 sm:p-3 xsm:p-3 w-full p-5 border-[#2E5DAB] rounded-l-lg" type="text" />
                                <button disabled={Loading} className="px-6 disabled:opacity-50 h-11 sm:h-8 xsm:h-8 md:h-10 bg-[#005CAB] hover:bg-[#0075d9] text-white font-normal xmd:px-4 rounded-r-lg">
                                    <BiSearchAlt2 className="text-2xl" />
                                </button>
                            </form>
                        </div>

                        {homeLogo &&
                            <div className="flex justify-center items-center w-[15%] xsm:hidden sm:hidden md:hidden xmd:w-[20%]">

                                <></>

                            </div>
                        }
                    </div>

                </div>

                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >

                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={filterTypes.full}
                        name="radio-buttons-group"
                    >
                        <ListItemButton onClick={() => setFilter(filterTypes.full)}>
                            <FormControlLabel control={
                                <Radio checked={filter === filterTypes.full} />} label="Full search" />
                        </ListItemButton>

                        <ListItemButton onClick={() => setFilter(filterTypes.external)}>
                            <FormControlLabel control={
                                <Radio checked={filter === filterTypes.external} />} label="External search" />
                        </ListItemButton>

                        <ListItemButton onClick={() => setFilter(filterTypes.internal)}>
                            <FormControlLabel control={
                                <Radio checked={filter === filterTypes.internal} />} label="Internal search" />
                        </ListItemButton>
                    </RadioGroup>
                </Menu>
            </div>
        </>
    )
}

export default SearchBar
