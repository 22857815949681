import ShortenText from '../Utility/shortenText';
import React from 'react';

const localeOptions = { year: 'numeric', month: 'long', day: '2-digit' }

export function pubdateCast(pubdate) {
    if (!pubdate)
        return null;

    if (pubdate instanceof Date)
        return pubdate

    if (pubdate instanceof Object)
        return new Date(Date.UTC(Number(pubdate.year), pubdate.month ? Number(pubdate.month) - 1 : 0, Number(pubdate.day) || 1))

    if (typeof pubdate === 'string')
        return new Date(pubdate)

    return null;
}

function Metadata({ article }) {
    const [isMore, SetMore] = React.useState(false)

    return (
        <>
            <p className="text-[#005CAB] text-base font-sans">
                Source: <span className='font-normal'> {article.source} </span>
            </p>

            {article.pubdate &&
                <p className='text-[#005CAB] text-base font-sans'>
                    Date: <span className='font-normal'> {pubdateCast(article.pubdate).toLocaleDateString(undefined, localeOptions)} </span>
                </p>
            }

            {article.authors && article.authors.length > 0 &&
                <p className='text-black text-base font-sans'>
                    Authors: <span className='font-normal'> {article.authors.slice(0, 7).join(" | ")}{article.authors.length > 7 && "..."} </span>
                </p>
            }

            {article.keywords && article.keywords.length > 0 &&
                <p className='text-black text-base font-sans'>
                    Keywords: <span className='font-normal'> {article.keywords.slice(0, 10).join(" | ")}{article.keywords.length > 10 && "..."} </span>
                </p>
            }

            {article.lemmas && article.lemmas.length > 0 &&
                <p className='text-black text-base font-sans'>
                    Lemmas: <span className='font-normal'> {article.lemmas.slice(0, 5).join(" | ")}{article.lemmas.length > 5 && "..."} </span>
                </p>
            }

            <p className='text-black text-base font-sans mt-2'>
                <span className='font-normal'> {isMore ? article.abstract : ShortenText(article.abstract, 300)} </span>
                { article.abstract.length > 300 &&
                    <span onClick={() => SetMore(!isMore)} className='text-[#005CAB] text-base font-sans hover:underline hover:cursor-pointer'>
                        {isMore ? " Show less" : " Show more"}
                    </span>
                }
            </p>
        </>
    )
}

export default Metadata
