import React, { useContext } from 'react'
import { MyContext } from '../../App'
import ArticleCard from './ArticleCard'

function ArticlesPaginated() {
    const { Loading, currentItems, query } = useContext(MyContext)

    return (
        <>
            {
                !Loading && currentItems && currentItems?.length === 0 ?
                    <p className='font-bold text-black text-3xl text-center'>No results found for: {query}</p>
                    :
                    !Loading && currentItems.map(article => {
                        return <ArticleCard article={article} />
                    })}

        </>
    )
}

export default ArticlesPaginated