import React from 'react';
import { Route, Routes } from 'react-router';
import Homepage from '../Components/Home/Home';
import Errornotfound from '../Components/Errors/404';
import FullArticle from '../Components/Articles/FullArticle';

import ArticlesSearchResults from "../Components/Articles/SearchResults"
import Login from '../Components/Login/Login';
import Register from '../Components/Register/Register';
import SharedWithYou from '../Components/SharedWithYou/SharedWithYou';
import Redirect from '../Components/Errors/Redirect';

const Routing = () => (
  <Routes>
    <Route exact path='/' element={<Homepage />} />
    <Route exact path='/results' element={<ArticlesSearchResults />} />
    <Route exact path='/fullArticle' element={<FullArticle />} />
    <Route exact path='/redirect' element={<Redirect />} />
    <Route exact path='/login' element={<Login />} />
    <Route exact path='/register' element={<Register />} />
    <Route exact path='/sharedwithyou' element={<SharedWithYou />} />
    <Route path="*" element={<Errornotfound />} />
  </Routes>
);

export default Routing;
