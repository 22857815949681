import React, { useContext } from "react";
import SearchBar from "../../Reusable/SearchBar";
import ScrollToTop from "../../ScrollToTop"
import { MyContext } from "../../App"
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { saveAs } from 'file-saver';
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import LottieFileAnalysis from "./LottieAnimation/LottieFileAnalysis";
import Metadata from '../../Reusable/Metadata';
import ShortenText from '../../Utility/shortenText';
import Highlighter from "react-highlight-words";

function FullArticle() {
    const { Loading, singleArticle, query, SetLoading } = useContext(MyContext)

    const refs = [];

    const navigate = useNavigate()

    const [selected, SetSelected] = React.useState()

    var doc = new jsPDF([297, 210]);
    const paperHeight = 297;
    const margin = 15;
    const pageHeight = paperHeight - margin * 2;

    const colWidth = 180;
    const maxColumns = 1;
    const padding = 5;
    const gutter = 15;
    const textOptions = {
        maxWidth: colWidth,
        /* Note that lineHeightFactor is not supported by getTextDimension() which always uses the default
         I'll try to submit a pull request to fix it soon */
    };
    let x = margin;
    let y = margin;
    let currColumn = 1;

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    let handleDownloadTXT = () => {
        var blob = new Blob([selected], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `${singleArticle?.title}.txt`);
    }

    const handleDownloadPDF = () => {
        const textAsArray = [singleArticle?.title].concat(selected.match(new RegExp("(\\S+\\s+){1," + (20 - 1) + "}\\S+", "g")))

        textAsArray.forEach(paragraph => {
            const height = doc.getTextDimensions(paragraph, textOptions).h;

            /* This method won't handle breaking a paragraph across columns, not sure how I would do that */
            if (y + height > pageHeight) {
                // next column
                currColumn += 1;
                x += colWidth + gutter;
                y = margin;

                if (currColumn > maxColumns) {
                    // next page
                    currColumn = 1;
                    doc.addPage();
                    x = margin;
                    y = margin;
                }
            }

            doc.text(paragraph, x, y, textOptions);
            y += height + padding;
        });
        doc.save(`${singleArticle?.title}.pdf`);
    }

    let handleTextSelect = () => {
        SetSelected(window.getSelection().toString())
    }

    return (
        <div className="w-full" >
            <ScrollToTop />

            {selected &&
                <ContextMenu id="same_unique_identifier" className="shadow-lg rounded-lg py-3 bg-white" >
                    <MenuItem onClick={() => copyToClipboard(selected)} className="hover:bg-gray-200 w-full hover:cursor-pointer px-4 my-1 text-lg text-left" >
                        Copy
                    </MenuItem>
                    <MenuItem divider />

                    <MenuItem onClick={() => handleDownloadPDF()} className="hover:bg-gray-200 w-full hover:cursor-pointer px-4 my-1 text-lg text-left" >
                        Download as pdf
                    </MenuItem>
                    <MenuItem divider />

                    <MenuItem onClick={() => handleDownloadTXT()} className="hover:bg-gray-200 w-full hover:cursor-pointer px-4 my-1 text-lg text-left" >
                        Download as txt
                    </MenuItem>
                </ContextMenu>}

            <div className="flex flex-col w-full xsm:px-2 sm:px-2 py-5 md:px-2 xmd:px-4 mb-10">
                <div className="flex flex-col w-full">

                    <div className="mb-6">
                        <SearchBar homeLogo={false} Loading={Loading}/>
                    </div>

                    <button disabled={Loading} onClick={() => navigate(-1)} className='py-2 disabled:opacity-50  xsm:px-4 xsm:py-1 sm:px-4 sm:py-1 px-6 bg-[#005CAB] hover:bg-[#0075d9] w-fit rounded-lg flex gap-x-1 mr-auto ml-14 xsm:ml-4 sm:ml-4  mb-10' >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M15 18l-6-6 6-6" /></svg>
                        <p className='text-white font-normal xsm:text-[12px] sm:text-[12px] my-auto'>Back</p>
                    </button>
                </div>

                <div className="flex px-14">

                    {Loading &&

                        <div className="flex flex-col w-full justify-center items-center mt-10">

                            <div className="flex">

                                <p className="text-[#005CAB] text-4xl md:text-3xl xsm:text-2xl sm:text-2xl  font-semibold tracking-wide font-sans uppercase" >We are analysing your chosen article!</p>

                            </div>

                            <div className="flex w-[750px] h-[750px] xsm:w-[250px] xsm:h-[250px] sm:w-[300px] sm:h-[300px] md:w-[400px] md:h-[400px] xmd:w-[400px] xmd:h-[400px] lg:w-[400px] lg:h-[400px]">

                                <LottieFileAnalysis />

                            </div>

                        </div>
                    }

                    {!Loading &&
                        <div className="flex flex-row w-full sm:flex-col-reverse md:flex-col-reverse xmd:flex-col-reverse xsm:flex-col-reverse justify-between gap-x-4 mt-8">
                            <div className="w-[73%] sm:mx-auto xsm:mx-auto md:mx-auto xmd:mx-auto  flex flex-col gap-y-8 sm:w-[95%] xsm:w-[95%] md:w-[95%] xmd:w-[85%] ">
                                <div className="mb-5">
                                    <p className="text-[#005CAB] text-5xl xmd:text-4xl xmd:w-full w-3/4 sm:w-full xsm:w-full md:w-full sm:text-2xl xsm:text-2xl md:text-3xl font-sans mb-4 text-left ">
                                        {singleArticle?.title}
                                    </p>
                                    <div className="text-left">
                                        <Metadata article={singleArticle}/>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-y-12 text-left">
                                    {
                                        Object.keys(singleArticle?.full_text).map((section, i) => {
                                            const ref = React.createRef()
                                            refs[i] = {
                                                ref,
                                                paragraphs: []
                                            }
                                            return (
                                                <div ref={ref}>
                                                    <p className="text-left text-black font-bold text-lg">{section}</p>
                                                    {
                                                        singleArticle?.full_text[section]['paragraphs'].map((paragraph, h) => {
                                                            const ref = React.createRef()
                                                            refs[i]['paragraphs'][h] = {
                                                                ref
                                                            }
                                                            return (
                                                                <>
                                                                    {

                                                                        <div className='mt-4' ref={ref}>
                                                                            <ContextMenuTrigger id="same_unique_identifier">
                                                                                <p onContextMenu={() => handleTextSelect()} className="text-left text-black font-normal text-lg w-fit">
                                                                                    { paragraph['main_sentences'] ?
                                                                                        <Highlighter
                                                                                            textToHighlight={paragraph.text}
                                                                                            findChunks={_ => paragraph.main_sentences}
                                                                                        /> :
                                                                                        paragraph.text
                                                                                    }
                                                                                </p>
                                                                            </ContextMenuTrigger>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="w-[28%] sm:w-[95%] xsm:w-[95%] sm:mx-auto xsm:mx-auto md:mx-auto xmd:mx-auto md:w-[95%] xmd:w-[85%] sm:mb-5 xsm:mb-5 md:mb-5 xmd:mb-5 flex flex-col gap-y-4">
                                {
                                    <div className="w-full h-[400px] overflow-y-scroll rounded-lg">
                                        <div className="w-full p-4 flex flex-col gap-y-6 rounded-lg bg-[#F1F2F2]">
                                            <p className="text-[#005CAB] text-lg font-bold text-left font-sans mb-1 ">
                                                {query ? "Paragraphs matching your query" : "Paragraphs"}
                                            </p>
                                            {
                                                Object.keys(singleArticle.full_text).map((section, i) => {singleArticle.full_text[section]['original_index'] = i; return section}).sort((section1, section2) => singleArticle.full_text[section2]['max_score'] -  singleArticle.full_text[section1]['max_score']).map(section => {
                                                    return (
                                                        <div className="flex flex-col gap-y-1">
                                                            <div className="flex flex-row items-center justify-between w-full" onClick={_ => {
                                                                refs[singleArticle.full_text[section]['original_index']].ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                                                            }}>
                                                                <p className="w-fit text-left text-[#005CAB] text-md font-semibold font-sans hover:cursor-pointer hover:underline">
                                                                    {section}
                                                                </p>
                                                                { query &&
                                                                    <p className="text-right text-[#005CAB] justify-self-end text-md font-semibold font-sans hover:cursor-pointer">AVG: {(Number(singleArticle.full_text[section]['score']) * 100).toFixed(2)}%</p>
                                                                }
                                                            </div>

                                                            <div className="flex flex-col ml-3 mt-2 gap-y-2">
                                                                {
                                                                    singleArticle.full_text[section]['paragraphs'].map((paragraph, i) => {
                                                                        return (
                                                                            <div className='flex flex-row w-full justify-between' onClick={_ => {
                                                                                refs[singleArticle.full_text[section]['original_index']]['paragraphs'][i].ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                                                                            }}>
                                                                                <p className="w-fit text-left text-black justify-self-start font-normal text-sm hover:cursor-pointer hover:underline">{ShortenText(paragraph.text, 40)}</p>
                                                                                { query &&
                                                                                    <p className="text-right text-black justify-self-end font-normal text-sm hover:cursor-pointer">{(Number(paragraph.score) * 100).toFixed(2)}%</p>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default FullArticle;