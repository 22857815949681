import React, { useContext } from 'react'
import { MyContext } from "../../App"
import HomeArticleCard from './HomeArticleCard';
import LottieFolder from '../Articles/LottieAnimation/LottieFolder';

function Favorite() {
  const { FavoriteArticles, Loading } = useContext(MyContext)
  return (
    <>
      <div className='font-sans flex flex-col gap-y-6 my-10 w-[85%] mx-auto' >
        {Loading &&

          <div className="flex flex-col w-full justify-center items-center mt-10">

                    <div className="flex">

                    <p className="text-[#005CAB] text-xl font-semibold tracking-wide font-sans uppercase" >We're searching for the last articles!</p>

                    </div>

                    <div className="flex w-[150px] h-[150px]">

                        <LottieFolder />

                    </div>

          {/*

              <Loader />
              <Skeletion2 />
              <Skeletion2 />
              <Skeletion2 />

          */}



          </div>
        }
        {FavoriteArticles.length === 0 &&
          <>
            <div className='h-[200px] flex items-center justify-center' >

              <h4 className='text-[#005CAB] text-lg font-thin'>You have no favorite articles in your list</h4>
            </div>
            <div className='h-[200px] flex items-center justify-center' >

            </div>
          </>
        }

        {!Loading && FavoriteArticles && FavoriteArticles !== [] && FavoriteArticles?.map((item, index) => {



          return (
            <HomeArticleCard item={item} toAdd={false} />

          )
        })}
      </div>
    </>
  )
}

export default Favorite