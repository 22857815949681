import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App"
import Logo from "../../Assets/Images/HomeLogo.svg"
import { BiSearchAlt2 } from "react-icons/bi"
import { useNavigate } from "react-router-dom";
import headerImage from "../../Assets/Images/Header.png"
import doctor from "../../Assets/Icons/DoctorIcon.svg"
import ReactPaginate from 'react-paginate';

function SharedWithYou() {
    const navigate = useNavigate()
    const { handleSearch, handleSearchBar } = useContext(MyContext)
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + 4;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / 4));
    }, [itemOffset]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * 4) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <div className="flex flex-col pb-16">



            <div style={{ backgroundImage: `url(${headerImage})`, backgroundSize: "cover" }} className="w-full py-10" >
                <div onClick={() => navigate('/')} className=" mx-auto w-[20%] xmd:w-[30%] xsm:w-[60%] sm:w-[60%] md:w-[40%] hover:cursor-pointer">
                    <img src={Logo} alt="mainlogo" className="w-full" />
                </div>
                <form onSubmit={(e) => handleSearch(e)} className="flex flex-row items-center w-[45%] xmd:w-[70%] xsm:w-[95%] sm:w-[95%] md:w-[95%]  mx-auto xsm:mt-3 sm:mt-3 md:mt-3" >
                    <input onChange={(e) => handleSearchBar(e)} className="border-y border-l focus:outline-none h-11 xsm:h-8 xsm:p-3 sm:h-8 sm:p-3 md:h-9 md:p-4 xmd:h-9 xmd:p-4 w-full p-5 border-[#2E5DAB] rounded-l-lg " type="search" />
                    <button className="text-white bg-[#2E5DAB] flex items-center h-11 w-fit  p-5 rounded-r-lg xsm:h-8 xsm:p-3 sm:h-8 sm:p-3 md:h-9 md:p-4 xmd:h-9 xmd:p-4" >
                        <BiSearchAlt2 size={window.innerWidth > 1000 ? 32 : 25} />
                    </button>
                </form>
            </div>

            <div className="pt-8">

                <button onClick={() => navigate("/")} className='py-2 xsm:px-4 xsm:py-1 sm:px-4 sm:py-1 px-6 bg-[#005CAB] hover:bg-[#0075d9] w-fit rounded-lg flex gap-x-1 mr-auto ml-14 xsm:ml-4 sm:ml-4  mb-10 sm:mt-8 xsm:mt-8 md:mt-6 xmd:mt-6' >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M15 18l-6-6 6-6" /></svg>
                    <p className='text-white font-normal xsm:text-[12px] sm:text-[12px] my-auto'>Back</p>
                </button>

            </div>

            <div className="flex flex-col  items-center">
                <p className="text-[#005CAB] text-4xl sm:text-2xl xsm:text-2xl md:text-3xl uppercase font-bold text-left font-sans  ">
                    Shared with you
                </p>
                <div className="grid grid-cols-2 sm:grid-cols-1 xmd:grid-cols-1 xsm:grid-cols-1 md:grid-cols-1 w-[75%] sm:w-[95%] xsm:w-[95%] md:w-[95%] mx-auto gap-10   mb-8 mt-12" >
                    {currentItems?.map(() => (
                        <div className="bg-[#fcfcfc] flex flex-row gap-x-7 sm:flex-col xsm:flex-col xsm:gap-y-2 sm:gap-y-4 w-full h-[250px] shadow-lg border border-[#c4c4c4c4] rounded-2xl " >
                            <div className='flex flex-col rounded-r-[80px] sm:rounded-r-2xl xsm:rounded-r-2xl sm:flex-row xsm:flex-row xsm:h-[30%] xsm:w-full xsm:justify-start xsm:gap-x-4 xsm:pl-4 sm:h-[30%] sm:w-full sm:justify-start sm:gap-x-4 sm:pl-4 h-full my-auto justify-evenly drop-shadow-2xl shadow-md rounded-l-2xl shadow-[#005CAB]  items-center w-[25%] bg-[#005CAB]'>
                                <img src={doctor} alt="" className='w-full h-[100px] md:h-[80px] xsm:h-[80%] xsm:w-fit sm:h-[80%] sm:w-fit' />
                                <div>

                                    <p className="text-white text-lg xsm:text-[14px] sm:text-[14px] md:text-[16px] text-center font-semibold text-left font-sans  ">Joseph Rox</p>

                                    <p className="text-white  xsm:text-[14px] sm:text-[14px] text-[10px] text-center font-thin text-center font-sans  ">Doctor Specialized in Surgery</p>
                                    <p className="text-white xsm:text-[14px] sm:text-[14px] text-[10px] text-center font-thin text-center font-sans  "> New York, NY</p>
                                </div>
                            </div>
                            <div className='flex flex-col w-[75%] xsm:w-full sm:w-full  h-[80%] my-auto justify-between p-3'>
                                <p className="text-[#005CAB] text-lg xsm:text-[14px] sm:text-[14px] md:text-[16px] underline font-bold text-left font-sans hover:cursor-pointer ">BRONCOVALEAS</p>
                                <p className='text-[#4B4B53] w-[90%] xsm:text-[12px] sm:text-[12px] md:text-[14px] font-semibold text-sm text-left'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus interdum interdum at urna eu blandit augue eget nulla. Vehicula condimentum euismod quam at ultricies cras ut adipiscing.Vehicula condimentum...</p>
                                {/* <button className='bg-[#005CAB] hover:bg-[#0075d9] py-2 px-8 text-white font-semibold w-fit h-fit rounded-lg'>Add Comment</button> */}
                            </div>
                        </div>
                    ))}


                </div>
            </div>
            <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                nextLabel="➤"
                nextClassName="text-[#005CAB] text-2xl"
                pageClassName="bg-white  rounded-xl text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white   text-[#005CAB] border-[#005CAB] border-2 border-[#005CAB] hover:bg-[#005CAB] hover:border-[#005CAB] active:shadow-inner active:bg-[#005CAB] active:border-[#005CAB]"
                pageLinkClassName="w-full h-full items-center justify-center flex"
                previousLabel="⮜"
                previousClassName="text-[#005CAB] text-2xl"
                activeClassName="bg-[#005CAB] text-center justify-center rounded-xl border border-[#005CAB] w-10 h-10 text-[#fff] hover:bg-[#005CAB] hover:border-[#005CAB] active:shadow-inner active:bg-[#005CAB] active:border-[#005CAB]"
                containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto  mb-12"
            />
        </div>

    )
}

export default SharedWithYou
