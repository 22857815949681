import React, { useContext } from 'react'
import Metadata from '../../Reusable/Metadata';
import { MyContext } from '../../App';

function HomeArticleCard({ article }) {
    const { getSingleArticle, Loading } = useContext(MyContext)

    return (
        <div className='flex flex-row justify-center items-center border-b pb-4 sm:flex-col xsm:flex-col md:items-start xmd:items-start' >
            <div className='w-[75%] text-left sm:w-full xsm:w-full' >
                <p onClick={() => article.id && getSingleArticle(article.id)} className='text-[#005CAB] text-xl font-sans mb-1 w-fit hover:underline hover:cursor-pointer'>{article.title}</p>
                <Metadata article={article} />
            </div>
        </div>
    )
}

export default HomeArticleCard