import React, { useContext } from 'react'
import SearchBar from '../../Reusable/SearchBar';
import ReactPaginate from 'react-paginate';
import ArticlesPaginated from './ArticlesPaginated';
import ScrollToTop from '../../ScrollToTop';
import { MyContext, sortingTypes } from "../../App"
import { useNavigate } from 'react-router-dom';
import IncyteLogo from "../../Assets/Images/HomeLogo.svg";
import "./Articles.css";
import LottieFolder from './LottieAnimation/LottieFolder';
import Menu from '@mui/material/Menu';
import RadioGroup from '@mui/material/RadioGroup';
import ListItemButton from '@mui/material/ListItemButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { pubdateCast } from '../../Reusable/Metadata';


function changeSorting(sorting, setSorting, result, SetResult, refreshArticlesList) {
    setSorting(sorting)
    SetResult(result.sort((a, b) => {
        switch (sorting) {
            case sortingTypes.newest:
            case sortingTypes.oldest:
                const pubdateA = pubdateCast(a.pubdate)
                const pubdateB = pubdateCast(b.pubdate)
                if (!pubdateA && !pubdateB)
                    return 0
                if (!pubdateA)
                    return 1
                if (!pubdateB)
                    return -1
                return sorting === sortingTypes.newest ? pubdateB - pubdateA : pubdateA - pubdateB
            default:
                return b.score - a.score
        }
    }))
    refreshArticlesList()
}

function SearchResults() {
    const { Loading, handlePageClick, pageCount, sorting, setSorting, result, SetResult, refreshArticlesList } = useContext(MyContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate()
    const rndInt = Math.floor(Math.random() * 10)

    const loadingPhrases =
        [
            "Take a sip of coffee while we find exactly what you need",
            "Gears turning, information fetching, satisfaction charging",
            "Finding the most accurate information to match your search",
            "Lean back and relax while we analyse your search ",
            "Please wait while our analyser searches our database",
            "Let us find the best suited information for you",
            "Fill your coffee cup while we ready your information ",
            "Testing your patience! It will be worth it",
            "Relax while we let our analysers work hard ",
            "Most accurate information coming right up!",
        ]

    return (
        <>
            <div className='flex flex-col items-center p-5 xsm:px-2 sm:px-2' >
                <div className="flex flex-col w-full">

                    <div className="mb-6">
                        <SearchBar homeLogo={false}/>
                    </div>

                    <div className="flex flex-row justify-items-start items-start justify-start">
                        <button onClick={() => navigate("/")} className='py-2 xsm:px-4 xsm:py-1 sm:px-4 sm:py-1 px-6 bg-[#005CAB] hover:bg-[#0075d9] w-fit rounded-lg flex gap-x-1 ml-14 xsm:ml-4 sm:ml-4 mb-10 text-white font-normal xsm:text-[12px] sm:text-[12px] my-auto' >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M15 18l-6-6 6-6" /></svg>
                            Back
                        </button>

                        {!Loading &&
                            <>
                                <button
                                    aria-controls={open ? 'sorting-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className='py-2 xsm:px-4 xsm:py-1 sm:px-4 sm:py-1 px-6 bg-[#005CAB] hover:bg-[#0075d9] w-fit rounded-lg flex gap-x-1 ml-14 xsm:ml-4 sm:ml-4 mb-10 text-white font-normal xsm:text-[12px] sm:text-[12px] my-auto'
                                >
                                    Sorting
                                </button>

                                <Menu
                                    id="sorting-menu"
                                    aria-labelledby="sorting-menu-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >

                                    <RadioGroup
                                        aria-labelledby="sorting-menu-group-label"
                                        defaultValue={sortingTypes.relevance}
                                        name="sorting-menu-buttons-group"
                                    >
                                        <ListItemButton onClick={() => changeSorting(sortingTypes.relevance, setSorting, result, SetResult, refreshArticlesList)}>
                                            <FormControlLabel control={
                                                <Radio checked={sorting === sortingTypes.relevance}/>} label="Relevance"/>
                                        </ListItemButton>

                                        <ListItemButton onClick={() => changeSorting(sortingTypes.newest, setSorting, result, SetResult, refreshArticlesList)}>
                                            <FormControlLabel control={
                                                <Radio checked={sorting === sortingTypes.newest}/>} label="Newest"/>
                                        </ListItemButton>

                                        <ListItemButton onClick={() => changeSorting(sortingTypes.oldest, setSorting, result, SetResult, refreshArticlesList)}>
                                            <FormControlLabel control={
                                                <Radio checked={sorting === sortingTypes.oldest}/>} label="Oldest"/>
                                        </ListItemButton>
                                    </RadioGroup>
                                </Menu>
                            </>
                        }
                    </div>

                </div>

                <ScrollToTop />

                {Loading &&

                    <div className="flex flex-col w-full justify-center items-center mt-10">

                        <div className="flex">
                            <p className="text-[#2E5DAB] text-4xl md:text-3xl xsm:text-2xl sm:text-2xl font-semibold tracking-wide font-sans uppercase" >YOUR SEARCH IS LOADING . . . <br /> {loadingPhrases[rndInt]}</p>
                        </div>

                        <div className="flex flex-col justify-center items-center relative ">

                            <div className="flex justify-center items-center w-full h-full xsm:h-fit sm:w-[85%] md:w-[80%] md:h-fit sm:h-fit">

                                <LottieFolder />

                            </div>

                            <div className="flex justify-center items-center w-full h-full my-auto xsm:mt-32 sm:mt-32 md:mt-32 mt-44 lg:mt-48 absolute animation z-10">

                                <img src={IncyteLogo} alt="" className="h-[200px] w-[200px] sm:h-[150px] sm:w-[150px] xsm:h-[150px] xsm:w-[150px] md:h-[160px] md:w-[160px]"></img>

                            </div>

                        </div>


                    </div>

                }

                {!Loading &&
                    <>
                        <ArticlesPaginated />

                        <ReactPaginate
                            breakLabel="..."
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            renderOnZeroPageCount={null}
                            nextLabel="➤"
                            nextClassName="text-[#005CAB] text-2xl hover:text-[#007BE5] active:text-[#004683]"
                            pageClassName="bg-white  rounded-xl text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white text-[#005CAB] border-[#005CAB] border-2 hover:bg-[#007BE5] hover:border-[#007BE5] active:shadow-innerbig active:bg-[#004683] active:border-[#004683]"
                            pageLinkClassName="w-full h-full items-center justify-center flex"
                            previousLabel="⮜"
                            previousClassName="text-[#005CAB] text-2xl hover:text-[#007BE5] active:text-[#004683]"
                            activeClassName="bg-[#005CAB] text-center justify-center rounded-xl border border-[#005CAB] w-10 h-10 text-white hover:bg-[#007BE5] hover:border-[#007BE5] active:shadow-innerbig active:bg-[#004683] active:border-[#004683]"
                            containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto pt-8  mb-12"
                        />
                    </>
                }

            </div>
        </>
    )
}

export default SearchResults